import React, { useEffect, useState } from 'react';
import {
    Grid, AppBar,
    Toolbar,
    Button,
    Alert,
    Snackbar,
    Box,
    Typography
} from '@mui/material';
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker } from 'rsuite';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { FormatNumber, FormatNumberRedeem } from '../../utils/formatNumber';
import Loading from '../Dashboard/Loading';
import TextField from '@mui/material/TextField';
import Datefilter from '../../utils/Datefilter';
import CardsMobileRes from './CardMobileRes';
import { purchaseVariables, redemptionVariables } from '../../Data/data';


export default function TransactionDatewise(props: any) {
    const SchemeType = {
        "ONGPGR": "NIPPON INDIA OVERNIGHT FUND (G)",
        "LPIGGR": "NIPPON INDIA LOW DURATION FUND (G)",
        "LFIGGR": "NIPPON INDIA LIQUID FUND (G)",
    }
    const [tranx, setTranx] = useState<any>([]);
    const [selectedDate, handleDateChange] = React.useState(null);
    const { folio_id } = useParams();
    const [isError, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [columns, setColumns] = useState([
        {
            field: 'id', headerName: 'S.No', width: 90, renderHeader: () => (
                <strong>
                    {'S.No'}
                </strong>
            ),
        },
        {
            field: 'SCHEME_NAME', headerName: 'Fund', width: 380, renderHeader: () => (
                <strong>
                    {'Fund'}
                </strong>
            ),
            renderCell: (params) => {
                let { SCHEME_NAME } = params.row;
                const schemName = SCHEME_NAME.split("/")[1]
                return <div style={{ minHeight: "50px", alignItems: "center", display: "flex", width: "100%" }}>{schemName}</div>
            }
        },
        {
            field: 'TRXN_TYPE',
            headerName: 'Transaction Type',
            width: 240, renderHeader: () => (
                <strong>
                    {'Transaction Type'}
                </strong>
            ),
            renderCell: (params) => {
                let { TRXN_TYPE } = params.row;
                const TransactionType = purchaseVariables.test(TRXN_TYPE) ? "Purchase" :  redemptionVariables.test(TRXN_TYPE) ? "Redemption" : TRXN_TYPE;
                return <div>{TransactionType}</div>;
            },
        },
        {
            field: 'UNITS', headerName: 'Amount', width: 180, renderHeader: () => (
                <strong>
                    {'Amount / Units'}
                </strong>
            ), renderCell: (params) => {
                const { UNITS, TRXN_STATUS,AMOUNT,TRXN_TYPE,ALL_UNITS } = params.row;
                const amount = TRXN_TYPE == "Redemption"?(ALL_UNITS == 'No'?`${FormatNumber(UNITS)} units`:`All units`):`Rs ${FormatNumber(AMOUNT)}`;
                if (TRXN_STATUS == 'Pending') {
                    // @ts-ignore
                    return <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} ><Typography variant='body1'>{amount}</Typography><Typography style={{ color: '#FDD017', textWrap: "wrap" }} variant='caption'>{TRXN_STATUS}</Typography></Box>
                } else if (TRXN_STATUS == "Rejected / Reversal") {
                    // @ts-ignore
                    return <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}><Typography variant='body1' >{amount}</Typography><Typography style={{ color: 'red', textWrap: "wrap" }} variant='caption'>{TRXN_STATUS.split("/")[0]}</Typography></Box>
                } else {
                    // @ts-ignore
                    return <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} ><Typography variant='body1' >{amount}</Typography><Typography style={{ color: 'green', textWrap: "wrap" }} variant='caption'>{TRXN_STATUS}</Typography></Box>
                }
            }
        },
        {
            field: 'AUTHORIZED_DATE_TIME', headerName: 'Transaction Date', width: 240, renderHeader: () => (
                <strong>
                    {'Transaction Date'}
                </strong>
            ),
        },
    ]);
    const today = new Date();
    const [loading, setLoading] = useState(false);
    const dateConverter = (str) => {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        var date = new Date(str);
        var mnth = (date.getMonth());
        var day = ("0" + date.getDate()).slice(-2);
        var year = date.getFullYear();
        return `${day}-${month[mnth]}-${year}`;
    }
    const [isDisable, SetisDisable] = useState(true);
    const [IntervalDate, setIntervalDate] = useState({
        startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        endDate: dateConverter(new Date().getTime()),
    });
    const [Interval, setInterval] = useState({
        startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        endDate: dateConverter(new Date().getTime()),
    });
    const getTranxData = async () => {
        setLoading(true);
        const startDate = new Date(IntervalDate.startDate).getTime();
        const endDate = new Date(IntervalDate.endDate).getTime();
        const diff = endDate - startDate
        const ThirtyDays = 1000 * 60 * 60 * 24 * 30;
        console.log(endDate - startDate)
        if (diff > ThirtyDays) {
            setLoading(false);
            setMessage("Difference between the dates cannot be more than 30 days");
            setError(true);
            return;
        }

        try {
            const res = axios.post(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/nse/transaction`, { iin: folio_id, from_date: IntervalDate.startDate, to_date: IntervalDate.endDate, triggered_trxn: "N", initiated_by: "B" },
                {
                    headers: {
                        Authorization: `Bearer ${props.accessToken}`,
                        device:'web',
                    }
                }).then((res) => {
                    const { data } = res;
                    setLoading(false)
                    console.log(data)
                    if (!data.succ) {
                        setError(true);
                        if (data.message == "Selected date difference Exceed 60 days") {
                            setMessage("Difference between the dates cannot be more than 60 days")
                        } else {
                            setMessage(data.message);
                        }
                        setTranx(data.resData);
                        return;
                    }
                    setTranx(data.resData);
                })

        } catch {
            setLoading(false)
            setError(true);
            setTranx([]);
            setMessage("Failed to Fetching Data from Server")
            return;
        }
        SetisDisable(true);
    }
    useEffect(() => {
        getTranxData()
    }, [])

    const changeHandler = (name, value) => {
        setIntervalDate((prev) => ({
            ...prev,
            [name]: dateConverter(value)
        }))
    }
    return <Grid container spacing={2} xs>
        <Grid item xs={12} sx={{ ml: { xs: 0, md: 4 }, height: '100vh' }}>
            <Snackbar
                open={isError}
                autoHideDuration={4000}
                onClose={() => setError(false)}
            >
                <Alert severity='error'
                    style={{ backgroundColor: "red" }}
                ><span style={{ color: "white" }} >{message}</span></Alert>
            </Snackbar>
            <AppBar sx={{ backgroundColor: "white", marginLeft: { xs: "5%", md: 0 }, display: 'flex', width: { xs: "96vw", md: '81.5vw' }, flexDirection: 'row', justifyContent: "flex-end" }} position="static" elevation={0}   >
                <Toolbar sx={{ display: 'flex', alignItems: "center", margin: '0px', padding: 0 }}>
                    {/* <TextField
                        label="Scheme"
                        name="scheme"
                        onChange={changeHandler}
                        value={filter.scheme}
                        sx={{ mr: 2 }}
                        select
                        size='small'
                    >
                        {schemes.map((each, idx) => {
                            return (
                                <MenuItem key={idx} value={each.value}>
                                    {each.name}
                                </MenuItem>
                            );
                        })}
                    </TextField> */}

                    <Datefilter name="startDate" label="From Date" value={IntervalDate.startDate} changeHandler={changeHandler} maxDate={Interval.endDate} minDate={Interval.startDate} inputFormat="dd-MMM-yyyy" />
                    <Datefilter name="endDate" label="To Date" value={IntervalDate.endDate} changeHandler={changeHandler} maxDate={Interval.endDate} minDate={Interval.startDate} inputFormat="dd-MMM-yyyy" />

                    {/* <DateRangePicker
                            onChange={changeHandler}
                            size='md'
                            appearance='subtle'
                            placement="bottomEnd"
                            editable={true}
                            defaultValue={[new Date(IntervalDate.startDate), new Date(IntervalDate.endDate)]}
                            showOneCalendar={true}
                            character="  to  "
                            format="dd-MM-yyyy"
                            ranges={[]}
                            cleanable={false}
                        /> */}

                    <Button sx={{ marginTop: "-3px", height: "37px" }} variant="contained" color="primary" onClick={getTranxData}>
                        Search
                    </Button>
                </Toolbar>
            </AppBar>
            {/* <Typography style={{ fontWeight: 600 }} variant="h6">Transactions</Typography> */}
            <Box sx={{ height: '85vh', display: { xs: "none", sm: "block" }, width: { xs: "96.5vw", md: '80vw' }, ml: { xs: 3, sm: 0 } }}>
                {loading ? <Loading /> : <DataGrid
                    sx={{ mt: 1 }}
                    getRowHeight={() => 'auto'}
                    //  hideFooter={true}
                    rowsPerPageOptions={[50, 100, 1000]}

                    rows={tranx.map((each: any, idx: number) => {
                        return { ...each, id: idx + 1 };
                    })}
                    columns={columns.map((each, idx) => {
                        return {
                            id: idx + 1,
                            ...each, headerAlign: 'center',
                            align: 'center',
                            sx: {
                                size: 2,
                            },
                        }
                    })}
                />}
            </Box>
            {loading ? <Loading /> : <Box sx={{ display: { xs: "flex", sm: "none" }, flexDirection: "column", alignItems: "center", width: "100%", marginLeft: "2.5vw" }}>
                {tranx.map((ele) => {
                    return <CardsMobileRes
                        scheme={ele.SCHEME_NAME?.split("/")[1]}
                        Amount={ele.AMOUNT}
                        date={ele.AUTHORIZED_DATE_TIME}
                        TranType={ele.TRXN_TYPE}
                        TranStatus={ele.TRXN_STATUS}
                        setFormData={""}
                        setIsModel={""}
                    />
                })}
            </Box>}
        </Grid>
    </Grid>
}
